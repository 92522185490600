<template>
  <div class="header_wrap" ref="header">
    <!-- Header Start -->
    <div class="main-header-new mt-4 px-3">
      <b-row align-h="between" class="d-flex justify-content-center align-items-center" no-gutters>
          <b-col cols="5" class="position-relative text-left">
            <b-row no-gutters>
              <b-col cols="6">
                <img src="../../../assets/images/etnex_home/Etnex-logo.png" class="nav-icon"  style="max-width: 70%;"/>
              </b-col>
              <b-col cols="3">
                <img src="../../../assets/images/etnex_home/twitter.png" class="nav-icon"  style="max-width: 50%;"/>
              </b-col>
              <b-col cols="3">
                <img src="../../../assets/images/etnex_home/telegram.png" class="nav-icon"  style="max-width: 50%;"/>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="7" class="position-relative text-right">
            <b-row no-gutters class="d-flex justify-content-center align-items-center">
              <b-col cols="7">
                
                <div class="dark-card">
                  <b-row no-gutters>
                    <b-col cols="3" class="d-flex justify-content-left align-items-left">
                      <img src="../../../assets/images/etnex_home/wallet.png" class="nav-icon"  style="max-width: 70%;"/>
                    </b-col>
                    <b-col cols="9" style="overflow: hidden;" class="text-white">
                      {{ username.length > 9 ? username.substring(0,4) + '.....' + username.slice(-4) : username }} 
                    </b-col>
                  </b-row>
                  
                </div>
              </b-col>
              <b-col cols="3">
                 <div class="dark-card ml-2">
                  <b-row no-gutters @click="openModal">
                      <!-- <img src="../../../assets/images/etnex_home/en.png" class="nav-icon" style="max-width: 80%;margin-left: auto;margin-right: auto;"  /> -->
                      <flag 
                        :iso="$i18n.locale === 'en' ? 'us' : ($i18n.locale === 'vn' ? 'vn' : 'cn')" 
                        style="width: 20px; height: 20px; border-radius: 16px; margin-left: auto; margin-right: auto;"
                      />

                  </b-row>
                </div>
              </b-col>
              <b-col cols="2">
                <router-link to="/web/me/news">
                <img src="../../../assets/images/etnex_home/Vector.png" class="nav-icon" style="max-width: 70%;"  />
                </router-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <transition name="modal">
          <div v-if="showModal" class="modal-backdrop-popup" @click="closeModal">
          <div class="modal-content-popup" @click.stop>
            <!-- <span class="close" @click="closeModal">&times;</span> -->
            <b-button @click="changeLang('en')" class="w-100 border-popup">
              <div class="text-white font-weight-bold d-flex text-14">
                <i class="header-icon-country"><flag iso="US" /></i
                ><span class="ml-2 text-white">{{ $t("en") }}</span>
              </div>
            </b-button>
            <b-button @click="changeLang('zh')" class="w-100 mt-2 border-popup">
              <div class="text-white font-weight-bold d-flex text-14">
                <i class="header-icon-country"><flag iso="CN" /></i
                ><span class="ml-2 text-white">{{ $t("zh") }}</span>
              </div>
            </b-button>
            <b-button @click="changeLang('vn')" class="w-100 mt-2 border-popup">
              <div class="text-white font-weight-bold d-flex text-14">
                <i class="header-icon-country"><flag iso="VN" /></i
                ><span class="ml-2 text-white">{{ $t("vn") }}</span>
              </div>
            </b-button>
          </div>
          </div>
        </transition>
      <!-- Header end -->
    </div>
  </div>
</template> 
<script>
import { mapActions } from "vuex";
export default {
  
  data() {
    return {
      path: "",
      username: "",
      showModal: false,
    };
  },
  methods: {
      ...mapActions(["storeHeight"]),
      handlesize() {
          let height = this.$refs.header.clientHeight;
          this.storeHeight(height);
      },

      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      changeLang(lang) {
        console.log(lang);
        this.$i18n.locale = lang;
        localStorage.setItem('lan', lang);
        this.closeModal();
      },
  },
  mounted() {
      this.handlesize();
      this.username = localStorage.getItem("username");
  },
};
</script>


<style lang="scss" scoped>

// custom popup
.modal-backdrop-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(71 71 71 / 50%);
  transition: opacity 0.3s ease;
}

.modal-content-popup {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.border-popup{
  border: 1px solid var(--Neon-Blue, #0BDDCC) !important;
}
// .modal-content-popup .close {
//   float: right;
//   cursor: pointer;
// }

.modal-enter-active, .modal-leave-active {
  opacity: 0;
}

.modal-enter-active .modal-content, .modal-leave-active .modal-content {
  transform: scale(0.9);
}

.modal-enter-to, .modal-leave-to {
  opacity: 0;
}

.modal-enter-to .modal-content, .modal-leave-to .modal-content {
  transform: scale(1);
}
</style> 