 <template>
  <div class="footer_wrap mt-auto" ref="footer">
    <!-- Footer Start -->
    <div class="app-footer">
      <b-row align-h="around" no-gutters>
        <b-col cols="2" class="position-relative">
          <div class="active_bg">
            <router-link tag="a" to="/web/wallet" class="nav-item-hold">
              <img :src="path == 'wallet' ? require('../../../assets/images/etnex_footer/home_active.png') : require('../../../assets/images/etnex_footer/home.png')" class="nav-icon" :class="{ active: path == 'wallet'}" />
              <span class="nav-text" :class="{'text-primary': path == 'wallet'}">{{ $t("home") }}</span>
            </router-link>
          </div>
         
        </b-col>
    
        <b-col cols="2" class="position-relative">
          <div class="active_bg">
             <router-link tag="a" to="/web/mywallet" class="nav-item-hold">
              <img :src="path == 'mywallet' ? require('../../../assets/images/etnex_footer/wallet_active.png') : require('../../../assets/images/etnex_footer/wallet.png')" class="nav-icon" :class="{ active: path == 'bonus'}" />
              <span class="nav-text" :class="{'text-primary': path == 'mywallet'}">{{ $t("wallet") }}</span>
            </router-link>
          </div>
         
        </b-col>

        <b-col cols="2" class="position-relative">
          <div class="active_bg">
            <router-link tag="a" to="/web/arbitrage" class="nav-item-hold">
              <img :src="path == 'arbitrage' ? require('../../../assets/images/etnex_footer/arbritrage_active.png') : require('../../../assets/images/etnex_footer/arbritrage.png')" class="nav-icon" :class="{ active: path == 'arbitrage'}" />
              <span class="nav-text" :class="{'text-primary': path == 'arbitrage'}">NEXA</span>
            </router-link>
          </div>
        </b-col>        

        <b-col cols="2" class="position-relative">
          <div class="active_bg">
            <router-link tag="a" to="/web/earnings" class="nav-item-hold">
              <img :src="path == 'earnings' ? require('../../../assets/images/etnex_footer/earnings_active.png') : require('../../../assets/images/etnex_footer/earnings.png')" class="nav-icon" :class="{ active: path == 'earnings'}" />
              <span class="nav-text" :class="{'text-primary': path == 'earnings'}">{{ $t("record") }}</span>
            </router-link>
          </div>
        </b-col>

        <b-col cols="2" class="position-relative">
          <div class="active_bg">
             <router-link tag="a" to="/web/me/mePage" class="nav-item-hold">
              <img :src="path == 'me' ? require('../../../assets/images/etnex_footer/profile_active.png') : require('../../../assets/images/etnex_footer/profile.png')" class="nav-icon" :class="{ active: path == 'mePage' }" />
              <span class="nav-text" :class="{'text-primary': path == 'me'}">{{ $t("profile") }}</span>
            
            </router-link>
          </div>
         
        </b-col>

      </b-row>
    </div>
  </div>
</template> 
<script>
import { mapActions } from "vuex";
export default {
  watch: {
    $route() {
      this.changePath();
    },
  },
  data() {
    return {
      path: "",
    };
  },
  methods: {
    ...mapActions(["storeHeight"]),
    handlesize() {
      let height = this.$refs.footer.clientHeight;
      this.storeHeight(height);
    },
    changePath() {
      this.path = this.$route.path.split("/")[2];
      console.log(this.path);
    },
  },
  mounted() {
    this.changePath();
    this.handlesize();
  },
};
</script>


<style lang="scss" scoped>
</style> 